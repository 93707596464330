import { configureStore } from '@reduxjs/toolkit'
import shops from './reducers/shopsSlice'
import transactions from './reducers/transactionsSlice'
import packages from './reducers/packagesSlice'
import trials from './reducers/trialSlice'
import offers from './reducers/offersSlice'
import revenues from './reducers/revenueSlice'
import paymentMethods from './reducers/paymentMethodsSlice'
import reports from './reducers/reportsSlice'
import customers from './reducers/customersSlice'
import orders from './reducers/ordersSlice'
import notifications from './reducers/alertnotificationsSlice'
import referrals from './reducers/referralsSlice'
import payouts from './reducers/payoutsSlice'
import payoutrequests from './reducers/withdrawlRequestsSlice'
import rewards from './reducers/rewardsSlice'
import shopsReferrals from './reducers/shopReferralsSlice'
import payoutPaymentMethods from './reducers/payoutPaymentMethodsSlice'


import collapsed from './reducers/collpseSlice'

export const store = configureStore({
  reducer: {
    shops,
    transactions,
    packages,
    trials,
    offers,
    revenues,
    paymentMethods,
    reports,
    customers,
    orders,
    notifications,
    referrals,
    payouts,
    payoutrequests,
    rewards,
    shopsReferrals,
    payoutPaymentMethods,
    collapsed
  }
})