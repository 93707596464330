import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux';
import logo from '../assets/images/logo.png'
export default function Sidebar() {

  const [activeItem, setActiveItem] = useState(1);

  const handleItemClick = (index, route) => {

    setActiveItem(index);
    navigate(route)
  };
  // const { admin } = useAuthContext()
  // const { adminLogout } = LogoutHook()
  // const handleLogout = () => {
  //   if (admin) {
  //     adminLogout()
  //   }
  // }
  const navigate = useNavigate()
  const location = useLocation();
  useEffect(() => {
    const updateActiveItem = () => {
      const route = location.pathname;
      switch (route) {
        case '/':
          setActiveItem(1);
          break;
        case '/tailorbhai/super/admin/shops':

          setActiveItem(2);
          break;
        case '/tailorbhai/super/admin/customers':

          setActiveItem(3);
          break;
        case '/tailorbhai/super/admin/orders':
          setActiveItem(4);
          break;
        case '/tailorbhai/super/admin/transactions':
          setActiveItem(5);
          break;
        case '/tailorbhai/super/admin/referrals':
          setActiveItem(6);
          break;
        case '/tailorbhai/super/admin/notifications':
          setActiveItem(7);
          break;
        case '/tailorbhai/super/admin/setting':
          setActiveItem(8)
          break;
          case '/tailorbhai/super/admin/plans&packages':
            setActiveItem(9)
            break;
            case '/tailorbhai/super/admin/offers':
            setActiveItem(10)
            break;
            case '/tailorbhai/super/admin/revenue':
            setActiveItem(11)
            break;
            case '/tailorbhai/super/admin/reports':
              setActiveItem(12)
              break;
        default:
          setActiveItem(1);
          break;
      }
    };
    updateActiveItem();
  }, [location.pathname]);

  const collapsed = useSelector((state) => state.collapsed.collapsed);

  return (
    <>

      <div className={`${collapsed ? "collapsed_sidenav" : "sidenav"}`}>
        <div className={`welcome  ${collapsed?"my-2":"my-4"} text-center`}>
          <h4 className='text-center'>Tailor Bhai</h4>
          <img  className='text-center' src={logo} alt="" />
        </div>
        <ul className={`${collapsed ? "pt-2 mt-2 px-3" : "pt-2 mt-2 "}`}>
          <li className={`${collapsed ? "mb-3 mt-1" : "my-2"}`} style={activeItem === 1 ? { backgroundColor: 'var(--primary)', border: '0px', borderRadius: '4px', fontWeight: '600', color: 'var(--white)', transition: 'background-color 0.9s', transform: '0.8s' } : {}} onClick={() => handleItemClick(1, `/`)}><svg style={activeItem === 1 ? { backgroundColor: 'var(--primary)', border: '0px', borderRadius: '4px', fontWeight: '700', color: 'var(--white)', transition: 'background-color 0.9s', transform: '0.8s' } : {}} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6A2.25 2.25 0 0 1 6 3.75h2.25A2.25 2.25 0 0 1 10.5 6v2.25a2.25 2.25 0 0 1-2.25 2.25H6a2.25 2.25 0 0 1-2.25-2.25V6ZM3.75 15.75A2.25 2.25 0 0 1 6 13.5h2.25a2.25 2.25 0 0 1 2.25 2.25V18a2.25 2.25 0 0 1-2.25 2.25H6A2.25 2.25 0 0 1 3.75 18v-2.25ZM13.5 6a2.25 2.25 0 0 1 2.25-2.25H18A2.25 2.25 0 0 1 20.25 6v2.25A2.25 2.25 0 0 1 18 10.5h-2.25a2.25 2.25 0 0 1-2.25-2.25V6ZM13.5 15.75a2.25 2.25 0 0 1 2.25-2.25H18a2.25 2.25 0 0 1 2.25 2.25V18A2.25 2.25 0 0 1 18 20.25h-2.25A2.25 2.25 0 0 1 13.5 18v-2.25Z" />
          </svg>
            <span>Dashboard</span>
          </li>
          <li className={`${collapsed ? "my-3" : "my-2"}`} style={activeItem === 2 ? { backgroundColor: 'var(--primary)', border: '0px', borderRadius: '4px', fontWeight: '600', color: 'var(--white)', transition: 'background-color 0.9s', transform: '0.8s' } : {}} onClick={() => handleItemClick(2, `/tailorbhai/super/admin/shops`)}>
            <svg style={activeItem === 2 ? { backgroundColor: 'var(--primary)', border: '0px', borderRadius: '4px', fontWeight: '700', color: 'var(--white)', transition: 'background-color 0.9s', transform: '0.8s' } : {}} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 21v-7.5a.75.75 0 0 1 .75-.75h3a.75.75 0 0 1 .75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349M3.75 21V9.349m0 0a3.001 3.001 0 0 0 3.75-.615A2.993 2.993 0 0 0 9.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 0 0 2.25 1.016c.896 0 1.7-.393 2.25-1.015a3.001 3.001 0 0 0 3.75.614m-16.5 0a3.004 3.004 0 0 1-.621-4.72l1.189-1.19A1.5 1.5 0 0 1 5.378 3h13.243a1.5 1.5 0 0 1 1.06.44l1.19 1.189a3 3 0 0 1-.621 4.72M6.75 18h3.75a.75.75 0 0 0 .75-.75V13.5a.75.75 0 0 0-.75-.75H6.75a.75.75 0 0 0-.75.75v3.75c0 .414.336.75.75.75Z" />
            </svg>
            <span>Shops</span>
          </li>
          <li className={`${collapsed ? "my-3" : "my-2"}`} style={activeItem === 3 ? { backgroundColor: 'var(--primary)', border: '0px', borderRadius: '4px', fontWeight: '600', color: 'var(--white)', transition: 'background-color 0.9s', transform: '0.8s' } : {}} onClick={() => handleItemClick(3, '/tailorbhai/super/admin/customers')}><svg style={activeItem === 3 ? { backgroundColor: 'var(--primary)', border: '0px', borderRadius: '4px', fontWeight: '700', color: 'var(--white)', transition: 'background-color 0.9s', transform: '0.8s' } : {}} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z" />
          </svg>
            <span>Customers</span>
          </li>
          <li className={`${collapsed ? "my-3" : "my-2"}`} style={activeItem === 4 ? { backgroundColor: 'var(--primary)', border: '0px', borderRadius: '4px', fontWeight: '600', color: 'var(--white)', transition: 'background-color 0.9s', transform: '0.8s' } : {}} onClick={() => handleItemClick(4, '/tailorbhai/super/admin/orders')}><svg style={activeItem === 4 ? { backgroundColor: 'var(--primary)', border: '0px', borderRadius: '4px', fontWeight: '700', color: 'var(--white)', transition: 'background-color 0.9s', transform: '0.8s' } : {}} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z" />
          </svg>
            <span>Orders</span>
          </li>
          <li className={`${collapsed ? "my-3" : "my-2"}`} style={activeItem === 5 ? { backgroundColor: 'var(--primary)', border: '0px', borderRadius: '4px', fontWeight: '600', color: 'var(--white)', transition: 'background-color 0.9s', transform: '0.8s' } : {}} onClick={() => handleItemClick(5, '/tailorbhai/super/admin/transactions')}>
            <svg style={activeItem === 5 ? { backgroundColor: 'var(--primary)', border: '0px', borderRadius: '4px', fontWeight: '700', color: 'var(--white)', transition: 'background-color 0.9s', transform: '0.8s' } : {}} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 18.75a60.07 60.07 0 0 1 15.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 0 1 3 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 0 0-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 0 1-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 0 0 3 15h-.75M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm3 0h.008v.008H18V10.5Zm-12 0h.008v.008H6V10.5Z" />
            </svg>
            <span>Transactions</span>
          </li>
          <li className={`${collapsed ? "my-3" : "my-2"}`} style={activeItem === 9 ? { backgroundColor: 'var(--primary)', border: '0px', borderRadius: '4px', fontWeight: '600', color: 'var(--white)', transition: 'background-color 0.9s', transform: '0.8s' } : {}} onClick={() => handleItemClick(9, '/tailorbhai/super/admin/plans&packages')}>
            <svg style={activeItem === 9 ? { backgroundColor: 'var(--primary)', border: '0px', borderRadius: '4px', fontWeight: '700', color: 'var(--white)', transition: 'background-color 0.9s', transform: '0.8s' } : {}} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="m21 7.5-9-5.25L3 7.5m18 0-9 5.25m9-5.25v9l-9 5.25M3 7.5l9 5.25M3 7.5v9l9 5.25m0-9v9" />
          </svg>
            <span>Plan & Packages</span>
          </li>
          <li className={`${collapsed ? "my-3" : "my-2"}`} style={activeItem === 10 ? { backgroundColor: 'var(--primary)', border: '0px', borderRadius: '4px', fontWeight: '600', color: 'var(--white)', transition: 'background-color 0.9s', transform: '0.8s' } : {}} onClick={() => handleItemClick(10, '/tailorbhai/super/admin/offers')}>
          <svg  style={activeItem === 10 ? { backgroundColor: 'var(--primary)', border: '0px', borderRadius: '4px', fontWeight: '700', color: 'var(--white)', transition: 'background-color 0.9s', transform: '0.8s' } : {}} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M21 11.25v8.25a1.5 1.5 0 0 1-1.5 1.5H5.25a1.5 1.5 0 0 1-1.5-1.5v-8.25M12 4.875A2.625 2.625 0 1 0 9.375 7.5H12m0-2.625V7.5m0-2.625A2.625 2.625 0 1 1 14.625 7.5H12m0 0V21m-8.625-9.75h18c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125h-18c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z" />
        </svg>

            <span>Offers</span>
          </li>
          <li className={`${collapsed ? "my-3" : "my-2"}`} style={activeItem === 11 ? { backgroundColor: 'var(--primary)', border: '0px', borderRadius: '4px', fontWeight: '600', color: 'var(--white)', transition: 'background-color 0.9s', transform: '0.8s' } : {}} onClick={() => handleItemClick(11, '/tailorbhai/super/admin/revenue')}>
        <svg style={activeItem === 11 ? { backgroundColor: 'var(--primary)', border: '0px', borderRadius: '4px', fontWeight: '700', color: 'var(--white)', transition: 'background-color 0.9s', transform: '0.8s' } : {}} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
        <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m-3-2.818.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
      </svg>
            <span>Revenue</span>
          </li>
          <li className={`${collapsed ? "my-3" : "my-2"}`} style={activeItem === 12 ? { backgroundColor: 'var(--primary)', border: '0px', borderRadius: '4px', fontWeight: '600', color: 'var(--white)', transition: 'background-color 0.9s', transform: '0.8s' } : {}} onClick={() => handleItemClick(12, '/tailorbhai/super/admin/reports')}>
      <svg style={activeItem === 12 ? { backgroundColor: 'var(--primary)', border: '0px', borderRadius: '4px', fontWeight: '700', color: 'var(--white)', transition: 'background-color 0.9s', transform: '0.8s' } : {}} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
        <path stroke-linecap="round" stroke-linejoin="round" d="M3 3v1.5M3 21v-6m0 0 2.77-.693a9 9 0 0 1 6.208.682l.108.054a9 9 0 0 0 6.086.71l3.114-.732a48.524 48.524 0 0 1-.005-10.499l-3.11.732a9 9 0 0 1-6.085-.711l-.108-.054a9 9 0 0 0-6.208-.682L3 4.5M3 15V4.5" />
      </svg>
            <span>Reported Issues</span>
          </li>
          <li className={`${collapsed ? "my-3" : "my-2"}`} style={activeItem === 6 ? { backgroundColor: 'var(--primary)', border: '0px', borderRadius: '4px', fontWeight: '600', color: 'var(--white)', transition: 'background-color 0.9s', transform: '0.8s' } : {}} onClick={() => handleItemClick(6, '/tailorbhai/super/admin/referrals')}>
            <svg style={activeItem === 6 ? { backgroundColor: 'var(--primary)', border: '0px', borderRadius: '4px', fontWeight: '700', color: 'var(--white)', transition: 'background-color 0.9s', transform: '0.8s' } : {}} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M18 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0ZM3 19.235v-.11a6.375 6.375 0 0 1 12.75 0v.109A12.318 12.318 0 0 1 9.374 21c-2.331 0-4.512-.645-6.374-1.766Z" />
            </svg>
            <span>Referals</span>
          </li>
          <li className={`${collapsed ? "my-3" : "my-2"}`} style={activeItem === 7 ? { backgroundColor: 'var(--primary)', border: '0px', borderRadius: '4px', fontWeight: '600', color: 'var(--white)', transition: 'background-color 0.9s', transform: '0.8s' } : {}} onClick={() => handleItemClick(7, '/tailorbhai/super/admin/notifications')}><svg style={activeItem === 7 ? { backgroundColor: 'var(--primary)', border: '0px', borderRadius: '4px', fontWeight: '700', color: 'var(--white)', transition: 'background-color 0.9s', transform: '0.8s' } : {}} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0" />
          </svg>
            <span>Notifications</span>
          </li>
          <li className={`${collapsed ? "my-3" : "my-2"}`} style={activeItem === 8 ? { backgroundColor: 'var(--primary)', border: '0px', borderRadius: '4px', fontWeight: '600', color: 'var(--white)', transition: 'background-color 0.9s', transform: '0.8s' } : {}} onClick={() => handleItemClick(8, '/tailorbhai/super/admin/setting')}><svg style={activeItem === 8 ? { backgroundColor: 'var(--primary)', border: '0px', borderRadius: '4px', fontWeight: '700', color: 'var(--white)', transition: 'background-color 0.9s', transform: '0.8s' } : {}} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 0 1 1.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.559.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.894.149c-.424.07-.764.383-.929.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 0 1-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.398.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 0 1-.12-1.45l.527-.737c.25-.35.272-.806.108-1.204-.165-.397-.506-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.108-1.204l-.526-.738a1.125 1.125 0 0 1 .12-1.45l.773-.773a1.125 1.125 0 0 1 1.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894Z" />
            <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
          </svg>
            <span>Setting</span>
          </li>
          {/* <li className={`${collapsed ? "my-3" : "my-2"}`} onClick={handleLogout}><svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 9V5.25A2.25 2.25 0 0 1 10.5 3h6a2.25 2.25 0 0 1 2.25 2.25v13.5A2.25 2.25 0 0 1 16.5 21h-6a2.25 2.25 0 0 1-2.25-2.25V15m-3 0-3-3m0 0 3-3m-3 3H15" />
          </svg>
            <span>Logout</span>
          </li> */}
        </ul>
      </div>
    </>
  )
}
